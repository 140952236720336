import {useTheme} from '../ThemeProvider';
import './footer.css';

function Footer() {
    const theme = useTheme();
    return (
        <div className={`${theme === 'light' ? 'footerDiv' : 'footerDark'} row`} id="footer">
            <div className='col-md-4 footerLabel1'>
                <p className='text-start plabel mb-2'>Our location</p>
                <p className='text-start mb-1'>Rent A Desk, Madhapur, HI-TECH City,</p>
                <p className='text-start mb-1'>Hyderabad, Telangana,</p>
                <p className='text-start mb-1'>Pin: 500081</p>
                <p className='text-start mt-3 mb-1'>
                    sales@vendventure.in</p>
                <p className='text-start  fs-6'>    Phone:  +91 99854 02433
                </p>
            </div>
            <div className='col-md-4 footerlabel2'>
                <p className='text-start plabel mb-2'>Quick links:</p>
                <p className='text-start mb-1'>About Us</p>
                <p className='text-start mb-1'>Website Development</p>
                <p className='text-start mb-1'>Mobile App Development</p>
                <p className='text-start mb-1'>Digital Marketing</p>
                <p className='text-start mb-1'>UIUX And visual, Graphic Designing
                </p>
            </div>
            <div className='col-md-4 footerlist'>
                <p className='text-start plabel mb-2'>Follow Us:</p>
                <div className='d-flex gap-3'>
                <a href="#" className="fa fa-facebook"></a>
                <a href="#" className="fa fa-twitter"></a>
                <a href="#" className="fa fa-youtube"></a>
                <a href="#" className="fa fa-instagram"></a>
                </div>
                </div>
            <div className='col hrLine'></div>
            <p className='text-center footerights'>@ 2024 VendVenture Pvt Ltd - Job Portal. All rights reserved</p>
        </div>
    );
}

export default Footer;
