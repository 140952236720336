import React,{useState} from 'react';
import './App.css';
import './AppDark.css'
import NavbarHeader from './components/navbar/navbar';
import {ThemeProvider} from './components/ThemeProvider';
function App() {
  const [theme, setTheme] = useState('light');

  const toggleTheme = (mode) => {
    console.log(mode,'mode00000')
    setTheme(mode);
  };

  return (
    <ThemeProvider theme={theme}>
    <div className={`${theme === 'light' ? 'App' : 'AppDark'}`}>
    <NavbarHeader toggleTheme={toggleTheme}/>
    </div>
    </ThemeProvider>
  );
}

export default App;
