
import {useTheme} from '../ThemeProvider';
import './aboutus.css';

function Aboutus() {
  const theme = useTheme();
  return (
  <div className={`${theme === 'light' ? 'bgaboutDivLight' : 'bgaboutDiv'} row containerDiv`} id="aboutus">
  <div className='col text-start'>
<h2 className={`${theme === 'light' ? 'text-dark' : 'text-light'} headerLabels pt-3 mb-4`}>  About Us</h2>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start mt-2 paragraph mb-2`}>Welcome to VendVenture, a leading provider of innovative digital solutions tailored to meet your unique needs. With a passion for creativity and a commitment to excellence, we strive to deliver exceptional results that exceed your expectations.</p>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-2`}><span className='fw-bold'>Our mission:</span><br/>
is to empower businesses of all sizes to succeed in the digital world. We believe that effective digital solutions should be accessible to everyone, and we work tirelessly to ensure that our services are not only cutting-edge but also affordable.</p>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-4`}>
<span className='fw-bold'>What We Do: </span><br/>
At VendVenture, we offer a comprehensive range of services designed to help you thrive in today's competitive market. From website and app development to digital marketing and branding, we have the expertise and experience to take your business to the next level.
</p>
<h3 className={`${theme === 'light' ? 'text-dark' : 'text-light'}`}><span className='fw-bold' style={{fontSize:'20px'}}>Why choose Us:</span></h3>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-2 mt-3`}>
<span className='fw-bold'>Expertise: </span><br/>
Our team of skilled professionals brings years of experience and industry knowledge to every project.
</p>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-2`}>
<span className='fw-bold'>Innovation: </span><br/>
We are constantly exploring new technologies and strategies to stay ahead of the curve.
</p>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-2`}>
<span className='fw-bold'>Customer Service: </span><br/>
Your satisfaction is our top priority, and we go above and beyond to ensure that your experience with us is positive and rewarding.
</p>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-2`}>
<span className='fw-bold'>Results-Driven: </span><br/>
We are committed to delivering tangible results that drive growth and success for your business.
</p>
<p className={`${theme === 'light' ? 'text-dark' : 'text-light'} text text-start paragraph mb-2`}>
<span className='fw-bold'>Get in Touch: </span><br/>
Ready to take your digital presence to new heights? Contact us today to learn more about our services and how we can help you achieve your goals.
</p>
</div>
  </div>
  );
}

export default Aboutus;
