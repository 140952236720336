import vendventurelogo from '../../assests/images/vendventurelogo1.png';
import vendventurelogodark from '../../assests/images/vend-venture-logodark.png';
import Aboutjob from '../aboutjob/aboutjob';
import Streetjobapp from '../streetjobapp/streetjobapp';
import Aboutus from '../aboutus/aboutus'; 
import Team from '../team/team';
import Client from '../client/client';
import Footer from '../footer/footer';
import {useTheme} from '../ThemeProvider';
import './navbar.css';

function NavbarHeader({toggleTheme}) {
  const theme = useTheme();

  const handleToggle=(mode)=>{
    toggleTheme(mode)
  }

  return (
    <div className='container-fluid'>
      <nav className={`${theme === 'light' ? 'navbar navbar-expand-lg navbar-light pt-4' :'navbar navbar-expand-lg navbar-dark pt-4'} font`}>
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent"> 
    <div className="row container-fluid" style={{margin: '0 7%',padding: '0'}}>
    <div className="col-lg-2">
    <img src={theme === 'light' ? vendventurelogo : vendventurelogodark } alt="logo" />
    </div>
      <div className="col-lg-7 ms-auto" >
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex justify-content-end">
        <li className="nav-item pe-2 "  >
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item pe-2" >
          <a className="nav-link" href="#aboutus">About us</a>
        </li>
        <li className="nav-item pe-2" >
          <a className="nav-link" href="#product">Products</a>
        </li>
        <li className="nav-item pe-2" >
          <a className="nav-link" href="#team">Team</a>
        </li>
        
        <li className="nav-item pe-2" >
          <a className="nav-link" href="#footer">Contact Us</a>
        </li>
      </ul>
    </div>
     
      <div className="col-lg-3">
      <form className="d-flex justify-content-center">
        <div className='row ' align='end'>
          <div className='col-lg-6 mt-2 ' align='end'>
          <div className="input-group flex-nowrap searchInput me-3 w-100">
            <span className="input-group-text searchIcon pe-2" id="addon-wrapping"><i class="fa fa-search" style={{color:'#D3D3D3',fontSize:'14px'}}></i></span>
            <input type="text" className=" searchInputField ps-0 pt-1 pb-1" style={{width: '100%',color:'#fff',fontSize:'14px'}} placeholder="Search" />
          </div>

          </div>
          <div className='col-lg-6 d-flex justify-content-center mt-2 ' >
            <div className='row d-flex flex-row '>
            <div className='col-4'>
            <p  className={`${theme === 'light' ? 'text-dark' :'text-light'} `}>Theme</p>
            </div>
            <div className='col-8 d-flex mt-1 '>
               <button type="button" className='darkbutton  mx-2 ' onClick={()=>handleToggle('dark')}/>
               <button type="button" className='lightbutton ' onClick={()=>handleToggle('light')}/>
              
            </div>
            

            </div>
           
          
        
        

          </div>

        </div>
      </form>
      
        
      
        {/* <input className="form-control me-2 searchLabel" type="search" placeholder="Search" aria-label="Search"/> */}
        
      
      {/* <div className='col-sm-12 border border-dark d-flex'>
        <div className='col-sm-12 col-lg-7 border border-primary'>
          <div className="input-group flex-nowrap searchInput me-3 w-100">
            <span className="input-group-text searchIcon pe-2" id="addon-wrapping"><i class="fa fa-search" style={{color:'#fff',fontSize:'19px'}}></i></span>
             <input type="text" className=" searchInputField ps-0" style={{width: '100%'}} placeholder="Search"/>
          </div>

        </div>
        <div className='col-sm-12 col-lg-5 border border-secondary'>
          <div className='d-flex'>
          
            <span style={{lineHeight:'38px'}} className={`${theme === 'light' ? 'text-dark' :'text-light'}`}>Theme</span>
             <button type="button" className='darkbutton m-2' onClick={()=>handleToggle('dark')}/>
            <button type="button" className='lightbutton mt-2' onClick={()=>handleToggle('light')}/>

          </div>

        </div>

      </div> */}
    </div>
     
    </div>
    </div>
  </div>
      </nav>
      <Aboutjob/>
      <div className={`${theme === 'light' ? 'streeappdiv streeappdiv1' : 'streetappdarkDiv'}`}>
      <Streetjobapp/>
      <Aboutus/>
      </div>
      <div className={`${theme === 'light' ? 'teambg' : 'teambgDark'}`}>
      <Team/>
      <Client/>
      </div>
      <Footer/>
    </div>
  );
}

export default NavbarHeader;
