
import quote from '../../assests/images/quote.png';
import Image1 from '../../assests/images/Image1.png';
import {useTheme} from '../ThemeProvider';
import './client.css';

function Client() {
  const theme = useTheme();
  return (
  <div className="row containerDiv" style={{marginBottom:'6%'}}>
  
    <div className="col text-start">
    <h2 className={`${theme === 'light' ? 'text-dark' : 'text-light'} mb-4 text-center headerLabels py-3`}>Clients Testimonial</h2>
    <div className='row mt-4'>
        <div className='col-lg-4 col-md-6'>
        <div className={`${theme === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} card mb-3 cardRadius `}>
  <div className="row g-0">
    <div className="col">
      <div className="card-body clientcard">
      <div>
       <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
       </div>
        <p className='my-4 textCard cardLabels'>The ability to filter and fine-tune to help find the perfect job is great, It's been a great help finding and choosing a work path, Street Job.</p>
        <div className='d-flex gap-4 justify-content-between'>
            <div className='text-end d-flex'>
            <img src={Image1} alt="img" height={50}/>
            <p className='mt-2 ms-2 textCard text-start cardLabels'>Rahul D<br/>
            Chef at Barbeque
            </p>
            </div>
            <div className='text-end'>
            <img src={quote} alt="quote"  className='text-end' width={40}/>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
        <div className='col-lg-4 col-md-6'>
        <div className={`${theme === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} card mb-3 cardRadius `}>
  <div className="row g-0">
    <div className="col">
      <div className="card-body clientcard">
      <div>
       <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
       </div>
        <p className='my-4 textCard cardLabels'>Thank you so much for your invaluable assistance with my job, Really Great Street Job App.</p>
        <div className='d-flex gap-4 justify-content-between'>
            <div className='text-end d-flex'>
            <img src={Image1} alt="img" height={50}/>
            <p className='mt-2 ms-2 textCard text-start cardLabels'>Sushmitha M<br/>
            Sales Head at South India Shoping Mall
            </p>
            </div>
            <div className='text-end'>
            <img src={quote} alt="quote"  className='text-end' width={40}/>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
        <div className='col-lg-4 col-md-6'>
        <div className={`${theme === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'} card mb-3 cardRadius `}>
  <div className="row g-0">
    <div className="col">
      <div className="card-body clientcard">
      <div>
       <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
        <span className="fa fa-star checked"></span>
       </div>
        <p className='my-4 textCard cardLabels'>Very happy I got job very easily from Street Job App, i will recommend to my friends and relatives.</p>
        <div className='d-flex gap-4 justify-content-between'>
            <div className='text-end d-flex'>
            <img src={Image1} alt="img" height={50}/>
            <p className='mt-2 ms-2 textCard text-start cardLabels'>Janardhan Kavu<br/>
            Carpainter at Private Shop
            </p>
            </div>
            <div className='text-end'>
            <img src={quote} alt="quote"  className='text-end' width={40}/>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
    </div>
    </div>
  </div>
  );
}

export default Client;
